import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

class Store {

    qntdItems = localStorage.getItem("qntdTotal") ? parseInt(localStorage.getItem("qntdTotal")) : 0;
    userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;
    userName = localStorage.getItem("userName");
    token = localStorage.getItem("token");
    eventId = localStorage.getItem("eventId");

    counter = 0;

    atualizaCarrinho(qntd){
        this.qntdItems = qntd;
    }

    addCounter(qntd){
        this.counter = qntd+1;
    }

    logout() {
        this.userId = null;
        this.userName = null;
        this.token = null;
        this.qntdItems = 0;
        this.eventId = null;
    }

    setUserName(userName) {
        this.userName = userName;
    }

    setUserId(userId) {
        this.userId = userId;
    }
    setToken(token) {
        this.token = token;
    }
    setEventId(eventId) {
        this.eventId = eventId;
    }

}

decorate( Store, {
    qntdItems: observable,
    counter: observable,
    atualizaCarrinho: action,
    addCounter: action,
    userId: observable,
    userName: observable,
    token: observable,
    eventId: observable,
})

export const StoreContext = createContext(new Store());