import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { transformStatus, getStatusClass } from './pedido_utils';

import Pagination from 'react-bootstrap/Pagination';

import api from '../../../services/api';
import Menu from '../menu';
import './styles.css';
import Load from '../../load';

export default function MeusPedidos() {

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [iniciou, setIniciou] = useState(false);
    const [orders, setOrders] = useState([]);
    const token = localStorage.getItem('token');
    var cont = 0;

    useEffect(() => {
        if (!iniciou) {
            loadOrders();
        }
    },);

    const loadOrders = async (pageNumber) => {
        setIniciou(true);
        try {
            setLoading(true);
            if (!pageNumber) pageNumber = 1;
            const response = await api.get(`/clients/orders?order_direction=desc&page=${pageNumber}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setOrders(response.data.data);
            setLastPage(response.data.lastPage)
            setPage(pageNumber);
        } catch (err) {
            alert('Erro ao carregar os pedidos');
            setOrders([]);
        } finally {
            setLoading(false);
        }
    };

    const mudaPagina = (page) => {
        loadOrders(page);
    }

    const pageRows = () => {
        const active = page;
        const items = [];
        for (let number = 1; number <= lastPage; number++) {
            items.push(
                <span key={number} className={active === number ? 'active' : null} onClick={() => mudaPagina(number)} >{number}</span>
            );
        }
        return items;
    }

    return (
        <div className="area_events_admin">
            <div className="content_client_geral">
                <Menu />
                <div className="content_client grey_accent">
                    <h1>Meus pedidos</h1>
                    <table className="table table-striped" style={{
                        marginRight: '2em',
                    }}>
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Data - Hora</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (!orders || orders.length === 0) ?
                                    <tr>
                                        <td colSpan={4}>
                                            <span className="no-events-found">
                                                Nenhum pedido encontrado.
                                            </span>
                                        </td>
                                    </tr>
                                    :
                                    orders.map(order => {
                                        cont++;
                                        var orderOrderAt = parseISO(order.order_at);
                                        var formattedOrderOrderAt = format(
                                            orderOrderAt,
                                            "dd 'de' MMMM ' às ' HH:mm 'hrs' ",
                                            { locale: pt }
                                        );
                                        return (
                                            <tr key={order.id} className={(cont % 2 === 0) ? "par" : "impar"}>
                                                <td>#{order.number}</td>
                                                <td className="order-date">{formattedOrderOrderAt}</td>
                                                <td className={"order-status " + getStatusClass(order.status)}>
                                                    {transformStatus(order.status)}
                                                </td>
                                                <td className="order-action">
                                                    <Link to={`/cliente/pedidos/${order.id}`} >
                                                        Visualizar 
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    })
                            }
                        </tbody>
                    </table>
                    <div style={{
                        marginBottom: '1em',
                        marginRight: '2em',
                        display: 'flex',
                        alignSelf: 'flex-end',
                    }}>
                        <div>
                            <Pagination size="sm">{pageRows()}</Pagination>
                        </div>
                    </div>
                    <Load loading={loading} />
                </div>
            </div>
        </div>
    );
}