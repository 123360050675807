import * as Yup from 'yup';

import validarCPF from './valida-cpf'

Yup.addMethod(Yup.string, "cpf", function (errorMessage) {
    return this.test(`validate-cpf`, errorMessage, function (value) {
        const { path, createError } = this;

        const valid = validarCPF(value);
        return (
        valid ||
        createError({ path, message: errorMessage })
        );
    });
});

export default Yup;