import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

export const dateFomatter = (data, formato) => {
  var dataInISO = parseISO(data);
  return format(
    dataInISO,
    formato,
    { locale: pt }
  );
};
