import React from "react";

import { Dialog, DialogContent } from "@material-ui/core";
import { FiLoader } from "react-icons/fi";

export default function Load({ loading, message }) {
  return (
    <Dialog open={loading}>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "25px",
        }}
      >
        <FiLoader />
        <span style={{ marginTop: "0.5em" }}>{ message ? message : "Carregando..." }</span>
      </DialogContent>
    </Dialog>
  );
}
