import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import './styles.css';

export default function PDVs(){

    const [ pdvs, setPdvs ] = useState([]);

    useEffect(()=>{
        api.get('site/pdvs').then(
            response => {
                setPdvs(response.data)
            }
        );
        
    }, []);
    
    return (
        <div className="list_events">
            <div className="cards cards_events">
                <center>
                    <h1>Pontos de Vendas</h1>
                </center>
                <ul>
                    {pdvs.map(pdv => {
                        return  <li className="items_event" key={pdv.id}>
                                    <center>
                                        <h3>{pdv.responsable}</h3>
                                        <p style={{marginLeft: 7}}>{pdv.place_of_establishment}</p>
                                    </center>   
                                </li>
                    })}
                </ul>
            </div>
        </div>
    );
}