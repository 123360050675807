import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Sobre from './components/sobre';
import PDVs from './components/pdvs';
import Contato from './components/contato';
import { PrivateRoute } from './privateroute';

import Login from './components/cliente/login';
import PVendas from './components/politica/pvendas';
import PPrivacidade from './components/politica/pprivacidade';
import PMeia from './components/politica/pmeia';
import ScrollToTop from './scrolltotop';
import Events from './components/events'
import Event from './components/event';
import MeusPedidos from './components/cliente/meuspedidos';
import Carrinho from './components/cliente/carrinho';
import Checkout from './components/cliente/checkout';
import MeusDados from './components/cliente/meusdados';
import BillingAddress from './components/cliente/billingaddress';
import Register from './components/cliente/register';
import Confirmation from './components/cliente/register/confirmation';
import ConfirmEmail from './components/cliente/register/confirmemail';
import MeusIngressos from './components/cliente/meusingressos';
import MeusIngressosDetalhes from './components/cliente/meusingressos/detalhes';

import DetalhesPedido from './components/cliente/meuspedidos/detalhes_pedido';
import LostPassword from './components/cliente/lostpassword';
import PasswordRedefinition from './components/cliente/senha/redefinicao';


export default function Routes() {

    return (
            
        <BrowserRouter basename='/'>
            <ScrollToTop />
                <Header /> 
                <Switch>
                    <PrivateRoute path="/cliente/meus_ingressos" exact component={MeusIngressos} />
                    <PrivateRoute path="/cliente/meus_ingressos/detalhes/:id" component={MeusIngressosDetalhes} />

                    <PrivateRoute path="/cliente/pedidos" component={MeusPedidos} exact />
                    <PrivateRoute path="/cliente/pedidos/:id" component={DetalhesPedido} />

                    <PrivateRoute path="/cliente/meus_dados" component={MeusDados} />
                    <PrivateRoute path="/cliente/endereco-cobranca" component={BillingAddress} />
                    <PrivateRoute path="/cliente/carrinho" component={Carrinho} />
                    <PrivateRoute path="/cliente/checkout" component={Checkout} />
                    <Route path="/cliente/login" component={Login} />
                    <Route path="/cliente/registrar" exact component={Register} />
                    <Route path="/cliente/lostpassword" exact component={LostPassword} />
                    <Route path="/cliente/senha/redefinicao/:token" exact component={PasswordRedefinition} />
                    <Route path="/cliente/registrar/confirmation/:token" component={Confirmation} />
                    <Route path="/cliente/registrar/confirm_email" component={ConfirmEmail} />

                    <Route path="/" exact component={Home} />
                    <Route path="/eventos" component={Events} />
                    <Route path="/evento/:id" component={Event} />
                    <Route path="/sobre" component={Sobre} />
                    <Route path="/pontos_de_venda" component={PDVs} />
                    <Route path="/contato" component={Contato} />
                    <Route path="/politica/vendas" component={PVendas} />
                    <Route path="/politica/privacidade" component={PPrivacidade} />
                    <Route path="/politica/meia_entrada" component={PMeia} />
                     
                </Switch>
                <Footer />
        </BrowserRouter>
    );
}