import React, { useState, useRef } from 'react';
import MaskedInput from "react-text-mask";

import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import Yup from './../../../utils/yup';
import { FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";

import api from '../../../services/api';
import './styles.css';

export default function Register() {

    const history = useHistory();
    const [registering, setRegistering] = useState(false);
    const captchaRef = useRef(null);

    const initialValues =  {name: "", cpf: "", email: "", phone: "", login: "", password: "", password_confirmation: ""};

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(4, "Mínimo 4 caracteres").required("* Nome obrigatório"),
        cpf: Yup.string().cpf('CPF inválido').required("* CPF obrigatório"),
        email: Yup.string().email("Digite um email válido").required("* Email obrigatório"),
        email_confirmation: Yup.string()
            .oneOf([Yup.ref('email'), null], 'Confirmação do email difere')
            .required("* Confirmação do Email obrigatória"),
        phone: Yup.string().required("* Telefone obrigatório"),
        password: Yup.string().min(6, "Mínimo de 6 caracteres").required("* Senha obrigatória"),
        password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Confirmação difere da Senha')
                .required("* Confirmação da Senha obrigatória"),
    });
    
    const cpfMask = [
        /[0-9]/,/[0-9]/,/[0-9]/,".",/[0-9]/,/[0-9]/,/[0-9]/,".",/[0-9]/,/[0-9]/,/[0-9]/,"-",/[0-9]/,/[0-9]/
    ]; 
    
    const phoneMask = [
        "(",/[0-9]/,/[0-9]/,")",/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,"-",/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/
    ]; 

    const handleSubmit = async (values) => {
        const { password, password_confirmation, name, email, phone } = values;
        const cpf = values.cpf.replace(/[^\d]+/g,'');

        const phone_country_code = 55;
        const phone_area_code = phone
            .substring(phone.indexOf('(') + 1, phone.lastIndexOf(')'))
            .replace(/[^\d]+/g,'');
        const phone_number = phone
            .substring(phone.lastIndexOf(')') + 1)
            .replace(/[^\d]+/g,'');
        
        try {
            setRegistering(true);
            const token = captchaRef.current.getValue();
            const response = await api.post(
                'clients', 
                { 
                    password, 
                    name, 
                    cpf, 
                    email,
                    password_confirmation,
                    phone_country_code,
                    phone_area_code,
                    phone_number,
                    token
                });
            if(response){
                alert('Cliente registrado com sucesso! \n\nFoi enviado um email \npara você confirmar seu cadastro!');
                history.push('/');
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                alert(err.response.data.error);
            } else {
                alert('Falha ao tentar criar o usuário');
            }
        } finally {
            setRegistering(false);
            captchaRef.current.reset();
        }
    };

    const doNotPaste = (e) => {
        e.preventDefault();
    };

    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    <FiUser />
                    <span > Tá na Mão - Área de Cadastro</span>
                </div>
                
                <Formik 
                    initialValues={initialValues} 
                    onSubmit={handleSubmit} 
                    validationSchema={validationSchema} >
                    { props => {
                        const { handleSubmit } = props;

                        return (
                            <FormikForm className="Form" onSubmit={handleSubmit}>
                                <Field type="text" className="Form-Field fadeIn third" name="name" placeholder="Digite seu nome completo" />
                                    <ErrorMessage className="form-error" component="span" name="name" />
                                
                                <Field  
                                    render={
                                        ({field}) => ( 
                                            <MaskedInput
                                                {...field}
                                                mask={cpfMask} 
                                                type="text" 
                                                className="Form-Field fadeIn third" 
                                                placeholder="CPF: 000.000.000-00" 
                                            />
                                        )
                                    }
                                    name="cpf" />
                                    <ErrorMessage className="form-error" component="span" name="cpf" />
                                
                                <Field 
                                    render={
                                        ({field}) => ( 
                                            <MaskedInput
                                                {...field}
                                                mask={phoneMask} 
                                                type="text" 
                                                className="Form-Field fadeIn third" 
                                                placeholder="(00)00000-0000" 
                                            />
                                        )
                                    }
                                    name="phone" />
                                    <ErrorMessage className="form-error" component="span" name="phone" />
                                
                                <Field type="text" className="fadeIn third" name="email" placeholder="Digite seu email" />
                                    <ErrorMessage className="form-error" component="span" name="email" />
                                
                                <Field type="text" className="fadeIn third" name="email_confirmation" placeholder="Confirmação do email"
                                    onPaste={doNotPaste} onDrop={doNotPaste} autoComplete="off"/>
                                    <ErrorMessage className="form-error" component="span" name="email_confirmation" />
                                
                                <Field type="password" id="password" className="fadeIn third" name="password" placeholder="Senha" />
                                    <ErrorMessage className="form-error" component="span" name="password" />

                                <Field type="password" id="password_confirmation" className="fadeIn third" name="password_confirmation" placeholder="Confirmação da Senha" 
                                    onPaste={doNotPaste} onDrop={doNotPaste} autoComplete="off"/>
                                    <ErrorMessage className="form-error" component="span" name="password_confirmation" />

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        ref={captchaRef}
                                        />
                                </div>

                                <input type="submit" className="fadeIn fourth" name="Registrar" value={registering ? "Registrando..." : "Registrar"} disabled={registering} />
                            </FormikForm>
                        );
                    }}
                    
                </Formik>
            </div>
        </div>

    );
}
