import React, { useEffect, useState } from 'react';

import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import Yup from './../../../utils/yup';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import maskString from '../../../utils/mask-string';

import api from '../../../services/api';
import Menu from '../menu';
import './styles.css';

export default function MeusDados(){

    const [saving, setSaving] = useState(false);
    const [ client, setClient ] = useState([]);
    const token = localStorage.getItem('token');
    const history = useHistory();
    const phoneMask = [
        "(",/[0-9]/,/[0-9]/,")",/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,"-",/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/
      ]; 

    useEffect( () => {      
        api.get(`authenticate`, { 
            headers: {
                Authorization: `Bearer ${token}`,      
            }            
        }).then( response => {
            setClient(response.data);
        }).catch( err => {
            console.log(err)
        });
    }, [token]);
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().min(4, "Mínimo 4 caracteres").required("* Nome obrigatório"),
        email: Yup.string().email("Digite um email válido").required("* Email obrigatório"),
        phone: Yup.string().required("* Telefone obrigatório"),
        password: Yup.string().min(6, "Mínimo de 6 caracteres").required("* Senha obrigatória"),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Confirmação difere da Senha')
            .required("* Confirmação da Senha obrigatória"),
    });

    const maskPhone = () => {
        if (client.phone_area_code && client.phone_number) {
            const value = client.phone_area_code + '' + client.phone_number;
            return maskString(value,  '(##)#####-####');
        } else {
            return '';
        }
    };

    const initialValues =  {
        name: client.name || '', 
        cpf: client.cpf || '', 
        email: client.email || '', 
        login: client.login || '', 
        phone: maskPhone(),
        password: '',
        password_confirmation: '',
    }

    const handleSubmit = async (values) => {
            const { login, password, password_confirmation, name, email, phone } = values;

            const phone_country_code = 55;
            const phone_area_code = phone
                .substring(phone.indexOf('(') + 1, phone.lastIndexOf(')'))
                .replace(/[^\d]+/g,'');
            const phone_number = phone
                .substring(phone.lastIndexOf(')') + 1)
                .replace(/[^\d]+/g,'');
            
            try {
                console.log(login);
                setSaving(true)
                const response = await api.put(`clients`, 
                { 
                    login: login.split(' ').join(''),
                    password, 
                    password_confirmation, 
                    name, 
                    email,
                    phone_country_code,
                    phone_area_code,
                    phone_number
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,      
                    }
                });
                if(response){
                    alert('Cliente editado com sucesso!');
                    history.push('/cliente/meus_dados');
                }
                                
            } catch (err) {
                alert( err.response.data.error );
            } finally {
                setSaving(false)
            }
        };

    return (
        <div className="area_events_admin">
            <div className="content_client_geral">
                <Menu />
                <div className="content_client">
                    <h1>Editar dados</h1>
                    <center>
                        <Formik initialValues={ initialValues } enableReinitialize={true} onSubmit={handleSubmit}
                            validationSchema={validationSchema} >
                            { props => {
                                const { handleSubmit } = props;
                                return (
                                    <FormikForm className="Form edit-my-data" onSubmit={handleSubmit} >
                                        <Field type="text" className="Form-Field fadeIn third" name="name" placeholder="Digite seu nome completo" />
                                            <ErrorMessage className="form-error" component="span" name="name" />
                                        
                                        <Field type="text" className="fadeIn third field_cpf" name="cpf" disabled />
                                            <ErrorMessage className="form-error" component="span" name="cpf" />

                                        <Field 
                                            render={
                                                ({field}) => ( 
                                                    <MaskedInput
                                                        {...field}
                                                        mask={phoneMask} 
                                                        type="text" 
                                                        className="Form-Field fadeIn third" 
                                                        placeholder="(00)00000-0000" 
                                                    />
                                                )
                                            }
                                            name="phone" />
                                            <ErrorMessage className="form-error" component="span" name="phone" />

                                        <Field type="text" className="fadeIn third" name="email" placeholder="Digite seu email" />
                                            <ErrorMessage className="form-error" component="span" name="email" />
                                        
                                        <Field type="password" id="password" className="fadeIn third" name="password" placeholder="Senha" />
                                            <ErrorMessage className="form-error" component="span" name="password" />

                                        <Field type="password" id="password_confirmation" className="fadeIn third" name="password_confirmation" placeholder="Confirmação da Senha" />
                                            <ErrorMessage className="form-error" component="span" name="password_confirmation" />
                                        <div></div>

                                        <input type="submit" className="fadeIn fourth" name="Salvar" value={saving ? 'Savando...' : 'Salvar'} disabled={saving} />
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </center>
                </div>
            </div>
        </div>
    );
}