import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import logo_footer from '../../assets/logo_footer.png';
import logo_google_play from '../../assets/google_play.png';
import logo_app_store from '../../assets/app_store.png';


export default function Footer(){
    return (
        <div className="footer">
            <div className="footer_container">
                <div className="logo_footer">
                    <img src={logo_footer} alt="Ta Na Mão" />
                </div>
                <div className="institucional">                    
                    <ul>
                        <li><h4>INSTITUCIONAL</h4><br/></li>
                        <li>
                            <Link to="/sobre">Sobre o Tá Na Mão</Link>
                        </li>
                        <li>
                            <Link to="/contato">Fale Conosco</Link>
                        </li>
                        <li>
                            <Link to="/eventos">Eventos</Link>
                        </li>
                    </ul>
                </div>
                <div className="politica">
                    <ul>
                        <li><h4>POLÍTICAS</h4><br/></li>
                        <li>
                            <Link to="/politica/vendas">Política de Vendas</Link>
                        </li>
                        <li>
                            <Link to="/politica/privacidade">Política de Privacidade</Link>
                        </li>
                        <li>
                            <Link to="/politica/meia_entrada">Política de Meia Entrada</Link>
                        </li>
                    </ul>
                </div>
                <div className="baixe_app">
                    <h4>BAIXE O APP NOS LINKS ABAIXO <span style={{fontSize: 10}}>(em breve)</span></h4>
                    <div className="google_play">
                        <a href="http://www.simplecodeti.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logo_google_play} alt="Google Play" />
                        </a>     
                    </div>
                    <div className="app_store">
                        <a href="http://www.simplecodeti.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logo_app_store} alt="App Store" />
                        </a>
                    </div>                    
                </div>
            </div>
            <div className="footer_copyright">
                <span>
                    Copyright 2020 © Desenvolvido por 
                    <a href="http://www.simplecodeti.com.br" target="_blank" rel="noopener noreferrer"> 
                        <span className="simple"> Simple</span>
                        <span className="code">Code</span> Desenvolvimento</a>
                </span>
            </div>
        </div>
    );
}