import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import api from '../../../services/api';
import { dateFomatter } from '../../../utils/date-formatter';

import { Pagination } from 'react-bootstrap';

import Menu from '../menu';
import './styles.css';
import Load from '../../load';

export default function MeusIngressos() {

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const token = localStorage.getItem('token');
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect( () => {      
        if (firstLoad) {
            loadTickets();
        }
    });

    const loadTickets = async (pageNumber) => {
        setLoading(true);
        try {
            if (!pageNumber) pageNumber = 1;
            const response = await api.get(`clients/tickets?order_direction=desc&page=${pageNumber}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTickets(response.data.data);
            setLastPage(response.data.lastPage)
            setPage(pageNumber);
        } catch (err) {
            console.log(err);
            alert("Erro ao carregar os ingressos");
        } finally {
            setLoading(false);
            setFirstLoad(false);
        }
    }

    const mudaPagina = (page) => {
        loadTickets(page);
    }

    const pageRows = () => {
        const active = page;
        const items = [];
        for (let number = 1; number <= lastPage; number++) {
            items.push(
                <span key={number} className={active === number ? 'active' : null} onClick={() => mudaPagina(number)} >{number}</span>
            );
        }
        return items;
    }

    return (
        <div className="area_events_admin">
            <div className="content_client_geral">
                <Menu />
                <div className="content_client grey_accent">
                    <h1>Meus ingressos</h1>
                    <center>
                        <table className="table table-striped table-responsive">
                            <thead>
                            <tr>
                                <th>Evento</th>
                                <th>Data - Hora</th>
                                <th>Tipo de ingresso</th>                                   
                                <th>Ações</th>
                            </tr>
                            </thead>  

                            <tbody>
                                {
                                    (!tickets || tickets.length === 0) ?
                                        <tr>
                                            <td colSpan={4}>
                                                <span className="no-events-found">
                                                    Nenhum ingresso encontrado.
                                                </span>
                                            </td>
                                        </tr>
                                        :
                                    tickets.map((ticket) => {
                                        return (
                                            <tr key={ticket.id}>
                                                <td>
                                                    { ticket.event.name }
                                                </td>   
                                                <td className="event-ticket-date">
                                                    { dateFomatter(ticket.event.start_at, "dd/MM/yyyy '-' HH:mm") }
                                                </td>
                                                <td className="event-ticket-type">
                                                    { ticket.ticketType.name } - { ticket.ticketType.type }
                                                </td>
                                                <td className="ticket-action">
                                                    {
                                                        // ticket.status === 'NOVO'
                                                        // ? 
                                                        <Link to={`/cliente/meus_ingressos/detalhes/${ticket.id}`} >
                                                        Visualizar/Imprimir 
                                                        </Link>
                                                        // : ticket.status.replaceAll('_', ' ')
                                                    }
                                                </td> 
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </center>
                    <div style={{
                        marginBottom: '1em',
                        marginRight: '2em',
                        display: 'flex',
                        alignSelf: 'flex-end',
                    }}>
                        <div>
                            <Pagination size="sm">{pageRows()}</Pagination>
                        </div>
                    </div>
                    <Load loading={loading} />
                </div>
            </div>
        </div>
    );
}