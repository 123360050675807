import React, { useContext } from 'react';

import { FiMail, FiUser, FiLogIn, FiLogOut } from 'react-icons/fi';
import { FiShoppingCart } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import  imgLogo from '../../assets/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { StoreContext } from '../store';
import './styles.css';

const Header = observer( () => {
    //console.log(Store);
    const history = useHistory();
    const storeContext = useContext(StoreContext);

    function userLogout(){
        localStorage.clear();
        storeContext.logout();
        history.push('/');
    }

    return (
        <div className="header_container">
            <div className="address_login">
                <div className="address">
                    <FaWhatsapp /> <span><a href="https://api.whatsapp.com/send?phone=5586994020372" rel="noopener noreferrer" target="_blank" > (86) 99402-0372 </a> |</span> <FiMail/> <span><a href="mailto:atendimento@tanamaoingressos.com.br" rel="noopener noreferrer" target="_blank" > atendimento@tanamaoingressos.com.br </a></span>
                </div>
                <div className="login">                    
                    { 
                        (storeContext.token == null) ? 
                        (<Link to="/cliente/login">
                            <FiUser />
                            Login
                        </Link>
                        ) :
                        (<div>
                            <span className="bem_vindo">Bem vindo(a) <b>{storeContext.userName?.toUpperCase()}</b></span>
                            <Link to="/cliente/pedidos"> 
                                <FiUser style={{marginRight: 5, marginBottom: -1}}/>
                                Área do Cliente 
                            </Link>
                            <Link to={""} onClick={userLogout}>
                                <FiLogOut />
                                Logout
                            </Link>
                        </div>)
                    }
                    {
                        (storeContext.token == null) ?
                        (<Link to="/cliente/registrar">
                            <FiLogIn />
                            Cadastrar
                        </Link>) : (
                            <span style={{backgroundColor: "#e7e7e7"}}></span>
                        )
                    }
                    
                </div>
            </div>
            <div className="logo_menu">
                <div className="logo">
                <Link to="/"><img src={imgLogo} alt="Logo Tá na Mão"/></Link>
                </div>
                <div className="menu">
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/eventos">Eventos</Link>
                        </li>
                        <li>
                            <Link to="/sobre">Sobre</Link>
                        </li>
                        <li>
                            <Link to="/contato">Contato</Link>
                        </li>
                    </ul>
                    <div className="cart-top" style={{clear: "both", float: "right", marginTop: 20, fontSize: 20}}>
                        <Link to="/cliente/carrinho" style={{color: "white"}}>
                            <FiShoppingCart />
                            <span style={{marginLeft: 5, marginRight: 5}}>
                                {
                                    storeContext.qntdItems
                                }
                            </span>
                            <span style={{fontSize: 15}}>
                                {
                                    storeContext.qntdItems > 1 ? <span>Itens</span> : <span>Item</span>
                                }
                            </span>
                        </Link>
                    </div>
                </div>                                 
            </div>           
        </div>
    );
});

export default Header;