import React, { useEffect, useState, useContext } from "react";

import { FiCalendar, FiMapPin, FiInfo } from "react-icons/fi";
import { FaTicketAlt, FaCartArrowDown } from "react-icons/fa";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../store";
import api from "../../services/api";

import AbadaTerms from "./../cliente/abadaterms";

import "./styles.css";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import SALE_DISABLED from  './evento_a_vender'
import { currencyFormat } from '../../utils/currency-formatter';
const EVENT_ID_MICODO = 69;

const Event = observer((props) => {
  const [evento, setEvento] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);
  const [eventDate, setEventDate] = useState(Date());
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [ticketTypeQuantities, setTicketTypeQuantities] = useState([]);
  const [openAbadaTerms, setOpenAbadaTerms] = useState(false);
  const [temTicketsAdicionados, setTemTicketsAdicionados] = useState(false);
  const [quantitiesValues, setQuantitiesValues] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  useEffect(() => {
    const id = props.match.params.id;
    if (id != null) {
      api.get(`/site/event/${id}`).then((response) => {
        if (response.data && response.data.length > 0) {
          setEvento(response.data[0]);
          if (response.data[0].id === EVENT_ID_MICODO) {
            setQuantitiesValues([0, 1]);
          }
          setTicketTypes(response.data[0].ticketTypes);
          const tmp = response.data[0].ticketTypes.map((t) => {
            return { id: t.id, quantity: 0 };
          });
          setTicketTypeQuantities(tmp);
          setEventDate(
            format(
              parseISO(response.data[0].start_at),
              "' Dia' dd 'de' MMMM', às ' HH:mm 'hrs' ",
              { locale: pt }
            )
          );
        }
      });
    }
  }, [props.match.params.id]);

  const handleCloseAbadaTerms = () => {
    setOpenAbadaTerms(false);
  }

  const setQuantityTicketType = (id, quantity) => {
    const index = ticketTypeQuantities.findIndex((t) => t.id === id);
    quantity = Number(quantity)
    if (index < 0) {
      ticketTypeQuantities.push({ id, quantity });
    } else {
      ticketTypeQuantities[index].quantity = quantity;
    }

    const tem = ticketTypeQuantities.filter((ticket) => Number(ticket.quantity) > 0).length > 0
    setTemTicketsAdicionados(tem);
  };

  const confirmaComprar = () => {
    let order = JSON.parse(localStorage.getItem("order")) ?? [];
    let qntdTotal = localStorage.getItem("qntdTotal")
      ? parseInt(localStorage.getItem("qntdTotal"))
      : 0;
    let valorTotal = localStorage.getItem("qntdTotal")
      ? parseInt(localStorage.getItem("valorTotal"))
      : 0;
    let n = localStorage.getItem("qntdTotal")
      ? parseInt(localStorage.getItem("qntdTotal"))
      : 0;
    let validade = new Date();

    for (const ticket of ticketTypeQuantities) {
      const ticketType = ticketTypes.find((type) => type.id === ticket.id);
      const price = ticketType.lote.price;
      const online_tax = calculateTax(ticketType.lote.price);
      for (let x = 0; x < ticket.quantity; x++) {
        order[n++] = {
          id: `${n}${ticketType.id}`,
          evento: evento,
          ticketType: ticketType,
          price,
          online_tax,
        };
      }
      qntdTotal += ticket.quantity;
      valorTotal += ticket.quantity * (price + online_tax);
    }

    localStorage.setItem("order", JSON.stringify(order));
    localStorage.setItem("qntdTotal", qntdTotal);
    localStorage.setItem("valorTotal", valorTotal);
    localStorage.setItem("validity", validade.toString());
    localStorage.setItem("eventId", evento.id);
    storeContext.atualizaCarrinho(qntdTotal);
    storeContext.setEventId(evento.id);
    if (localStorage.getItem("token") != null) {
      history.push("/cliente/carrinho");
    } else {
      history.push("/cliente/login");
    }
  };

  const calculateTax = (value) => {
    return (value * (evento.online_tax / 100));
  }

  const comprar = () => {
    if (!temTicketsAdicionados) {
      setDialogMessage('Nenhum ingresso adicionado')
      return;
    }

    if (evento.id === EVENT_ID_MICODO) {
      const qtdAdicionadaAgora = ticketTypeQuantities.filter((ticket) => Number(ticket.quantity) > 0).length;
      const qtdJaAdcionadaAoCarrinho = Number(localStorage.getItem("qntdTotal")) || 0;
      if (qtdAdicionadaAgora > 1) {
        setDialogMessage('É permitido apenas um ingresso por usuário.')
        return;
      }
      if ((qtdAdicionadaAgora + qtdJaAdcionadaAoCarrinho) > 1) {
        setDialogMessage('É permitido apenas um ingresso por usuário.\nVerifique seu carrinho, talvez já tenha adicionado o ingresso lá.')
        return;
      }
    }
    
    confirmaComprar();
  };

  return (
    <div className="card event_single_container">
      <AbadaTerms
        open={openAbadaTerms}
        evento={evento}
        onClose={() => handleCloseAbadaTerms()}
        agreeTerms={(agree) => {
          if (agree === true) {
            comprar();
          }
        }}></AbadaTerms> 
      <div className="card event_single_container">
        <div
          className="banner_image"
          style={{ backgroundImage: "url(" + evento.image_banner + ")" }}
        ></div>
        <p className="title_event"> Evento: {evento.name}</p>
        <div className="content_event">
          <div className="dados_evento">
            <p className="date_event">
              <FiCalendar color="grey" />
              {eventDate}
            </p>
            <p>
              <strong>
                <FiMapPin color="grey" /> {evento.place_of_establishment}
              </strong>
            </p>
            <p>
              <FiInfo color="grey" /> {evento.description}
            </p>
          </div>
          { !SALE_DISABLED && evento.active_online_sale && ticketTypes.length > 0 ? (
            <div className="dados_ingressos">
              <form>
                <p>
                  <FaTicketAlt className="ticket_icon" />
                  <strong> Tipos de Ingressos: </strong>
                </p>
                <ul>
                  {ticketTypes.map((ticketType) => {
                    return (
                      <li key={ticketType.id}>
                        <div className="desc_qntd_ticket_type">
                          <div className="desc_ticket_type">
                            {ticketType.name}
                            {" - " + ticketType.type}
                            <div className="value_ticket_type">
                              <div>
                                <span
                                  style={{ fontWeight: 100, fontSize: 14 }}
                                >
                                  Preço Unitário{" "}
                                </span>
                                <span style={{ fontWeight: 700 }}>
                                  {currencyFormat(ticketType.lote.price)}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{ fontWeight: 100, fontSize: 14 }}
                                >
                                  Taxa de conveniência {" "}
                                </span>
                                <span style={{ fontWeight: 700 }}>
                                  {currencyFormat(calculateTax(ticketType.lote.price))}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="quantity_ticket_type">
                            <span>Quantidade: </span>
                            <select key={ticketType.id} name={ticketType.id} 
                              onChange={(e) => setQuantityTicketType(ticketType.id, e.target.value)}>
                              {  quantitiesValues.map((element) => {
                                  return (
                                    <option value={element} key={element}>
                                      {element}
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <br />
                <br />
                <button 
                  type="button" 
                  onClick={() => {
                    if (evento.title_terms && evento.text_terms) {
                      setOpenAbadaTerms(true);
                    } else {
                      comprar()
                    }
                  }} 
                  className="btn_finalizarcompra" 
                  disabled={!temTicketsAdicionados}
                  >
                  <FaCartArrowDown />
                  <span style={{ marginLeft: 5 }}>Comprar</span>
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Dialog
        open={dialogMessage !== null}>
        <DialogTitle>Alerta</DialogTitle>
        <DialogContent>
          <div>
            { dialogMessage }
          </div>
        </DialogContent>
        <DialogActions>
          <input type="button"  value='OK' onClick={() => setDialogMessage(null) } />
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Event;
