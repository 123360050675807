import React, { useEffect, useState/*, useContext*/ } from 'react';

// import { FiCalendar, FiMapPin, FiInfo } from 'react-icons/fi';
// import { FaTicketAlt, FaCartArrowDown } from 'react-icons/fa';
// import { format, parseISO }  from 'date-fns';
// import  pt  from 'date-fns/locale/pt-BR';
// import { useHistory } from 'react-router-dom';
// import { StoreContext } from '../../store';
// import api from '../../../services/api';
import apiPS from '../../../services/apips';

import './styles.css';
 
function Checkout(){

    const [ session, setSession ] = useState([]);
    const emailPS = "marcelophb@gmail.com";
    const tokenPS = "0CC32620570A4DB393A70BFBDD67ECD8";

    useEffect(()=>{
        apiPS.post(`/sessions?email=${emailPS}&token=${tokenPS}`, { 
            headers: {
                AccessControlAllowOrigin: "http://localhost:3000/cliente/checkout",
                ContentType: 'application/x-www-form-urlencoded; charset=utf-8',
                //Authorization: `Bearer ${token}`,      
            }            
        }).then(
            response => {
                setSession(response);
                console.log(session);
            }
        );
    }, [session]);

    return (
        <div className="checkout-pag-seguro">
            
            <div className="content">

                <h3>Apenas em construção - ainda a testar</h3>

            </div>
            
        </div>
    );

}

export default Checkout;