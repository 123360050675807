import React from 'react';

import './styles.css';

export default function PVendas(){
    return (
        <div className="pprivacidade_content">
            <div className="content">
            <h1>Políticas de Privacidade</h1>
            <p>
            O Tá na Mão Ingressos do seu jeito, a fim de demonstrar o compromisso com a segurança e privacidade das informações coletadas dos usuários em seus canais web, mobile e em nossa bilheteria, criou a Política de Privacidade.
            </p>
            <p>
            Data da última atualização: 03/04/2020
            </p>
            <p>
            <h1>INFORMAÇÕES QUE CAPTURAMOS E GUARDAMOS</h1>
            <h3>DADOS PESSOAIS</h3>
            </p>
            <p>
            Dados Pessoais - Ao comprar ingressos ou cadastrar-se em eventos disponíveis nos canais de vendas da Ta na Mão - Ingressos, haverá a coleta dos dados pessoais dos usuários, que ficarão armazenados nos servidores da Ta na Mão - Ingressos como: nome completo, endereço, telefone celular, CPF, data de nascimento e e-mail. Estes dados são os mínimos necessários para a aquisição dos ingressos pelos usuários.
            </p>
            <p>
            Dados do Facebook – Ao utilizar sua conta no Facebook para se integrar aos nossos serviços, poderemos coletar todos os dados que forem disponibilizados pelo Facebook ao nosso sistema, como informações sobre seus anúncios, quantos clicks eles receberam, informações sobre os visitantes aos seus websites que estejam disponíveis nessa plataforma, entre outras.
            </p>
            <p>
            Dependendo do evento, dados extras podem ser solicitados pela produção do evento, ficando ao critério de cada usuário fornecer as suas informações. Tais informações serão gravadas apenas para este evento e não serão utilizados para outros fins.
            </p>
            <p>
            Dados financeiros - Os dados de pagamento e efetivação da compra, exigidas para o processamento da transação, bem como, os dados do cartão de crédito dos usuários, não são armazenados nos servidores da Ta na Mão - Ingressos.
            </p>
            <p>
            Além das informações recebidas durante os procedimentos de cadastro e pagamento, descritos acima, também há o recebimento de informações quando o usuário entra em contato com a Ta na Mão - Ingressos ou responde a uma de suas pesquisas. Estas informações podem ser incluídas no cadastro de cada usuário.
            </p>
            <p>
            Os usuários assumem a responsabilidade sobre as informações pessoais que estão fornecendo à Ta na Mão - Ingressos, bem como, as consequências de suas decisões.
            </p>
            <p>
            DADOS DE NAVEGAÇÃO E COOKIES
            Informações de navegação através do site – Ao acessar e interagir com o site e aplicativos, coletamos informações que podem identificar direta ou indiretamente um usuário em particular, como o endereço IP utilizado, sua localização geográfica aproximada, o registro das páginas visitadas, tempos de visita e outras informações agregadas.
            </p>
            <p>
            Cookies são pequenos trechos de texto usados para armazenar informações em navegadores da Web. Os cookies são usados para armazenar e receber identificadores e outras informações em computadores, telefones e outros dispositivos. Outras tecnologias, inclusive os dados que armazenamos em seu navegador ou dispositivo, identificadores associados ao seu dispositivo e outro software, são usados com finalidades semelhantes.
            </p>
            <p>
            A Ta na Mão - Ingressos utiliza cookies para ajudar a identificar os visitantes e evitar, por exemplo, que a senha do usuário tenha que ser digitada várias vezes durante a utilização do site. O navegador ou dispositivo pode ter configurações que permitam ao usuário escolher se quer definir cookies ou não e excluí-los. Determinadas partes dos Serviços da Ta na Mão - Ingressos poderão não funcionar corretamente se o uso de cookies do navegador tiver sido desativado.
            </p>
            <p>
            COMO UTILIZAMOS AS INFORMAÇÕES COLETADAS?
            Vale reforçar que todas informações coletadas são para fornecer o melhor serviço. As suas informações são sempre trafegadas utilizando criptografia e guardadas em servidores, que observam padrões rígidos de segurança, inviolabilidade e confiabilidade, de modo a garantir a intimidade, vida privada, honra e imagem dos usuários.
            </p>
            <p>
            Mediante requerimento do usuário, a Ta na Mão - Ingressos procederá a exclusão definitiva dos dados pessoais, salvo nos casos de guarda obrigatória.
            </p>
            <p>
            CONFIRMAÇÃO DE CONTA
            Para garantir a entrega segura dos nossos serviços, é necessário, ao se cadastrar, confirmar o cadastro e ativar a conta, através de um e-mail que o usuário irá receber da Ta na Mão - Ingressos. Ao proceder ao cadastro e confirmá-lo por e-mail, o usuário expressa o consentimento sobre coleta, uso, armazenamento e tratamento de dados pessoais pela Ta na Mão - Ingressos.
            </p>
            <p>
            Na efetivação da compra, o cadastro poderá ser submetido ao departamento de segurança de informações da Ta na Mão - Ingressos, que poderá entrar em contato com o usuário e solicitar detalhamento das informações.
            </p>
            <p>
            COMPROVANTE DE COMPRAS/ENTREGA DE INGRESSOS
            Após a efetivação das compras, o usuário receberá um e-mail no endereço eletrônico indicado. Assim, é muito importante que os dados do usuário estejam sempre atualizados na plataforma da Ta na Mão - Ingressos.
            </p>
            <p>
            Qualquer eventualidade com as apresentações, cancelamentos e/ou avisos, a equipe da Ta na Mão - Ingressos utilizará as informações cadastrais para entrar em contato e oferecer o melhor serviço.
            </p>
            <p>
            Informações mais detalhadas sobre os procedimentos de compras podem ser consultadas na nossa Política de Vendas.
            </p>
            <p>
            MARKETING E POLÍTICA ANTI-SPAM
            Criar e enviar e-mail marketing exige um criterioso processo a ser seguido, para preservação da privacidade e segurança do cliente.
            </p>
            <p>
            O E-mail Marketing é uma ferramenta importante para a comunicação com o cliente. Qualquer e-mail marketing enviado pela Ta na Mão - Ingressos contará com o consentimento do cliente (Opt-in), pois ele mesmo opta por receber através de uma inscrição ou implicitamente quando é estabelecido um contato entre as partes, sem que se utilize de meios intrusivos.
            </p>
            <p>
            A Ta na Mão - Ingressos tem uma Política Anti Spam e segue as regras e boas práticas de marketing não enviando Spam. A Ta na Mão - Ingressos obedece a todos os critérios do Código de Autorregulamentação para a Prática de E-mail Marketing (CAPEM – www.capem.org.br.)
            </p>
            <p>
            Todos os e-mails enviados pela Ta na Mão - Ingressos possuem o link de opt-out (opção de descadastramento do e-mail para receber futuras mensagens), desse modo é respeitada integralmente a privacidade e liberdade de quem recebe a mensagem.
            </p>
            <p>
            MELHORIAS NO SERVIÇO DA TA NA MÃO - INGRESSOS
            Informações sobre o comportamento do usuários nas nossas plataformas, como por exemplo, quantas vezes visitou o site também poderão ser armazenados e serão utilizados para aprimoramento dos serviços oferecidos pela Ta na Mão - Ingressos e ações de marketing mediante consentimento explícito.
            </p>
            <p>
            COM QUEM COMPARTILHAMOS ESTAS INFORMAÇÕES?
            Os dados pessoais serão disponibilizados apenas a empregados autorizados e repassados aos produtores, patrocinadores e empresas envolvidas no evento que se comprometem a cumprir a presente política de privacidade, não sendo compartilhados publicamente ou utilizados para finalidades que não as propostas inicialmente.
            </p>
            <p>
            Salvo situações legais, a Ta na Mão - Ingressos não fornecerá a terceiros, a informação de dados pessoais, registros de conexão e de acesso a aplicações de internet.
            </p>
            <p>
            A escolha na utilização de algumas formas de pagamento de terceiros, como PayPalⓇ ou RapiddoⓇ ou a participação de pré-vendas com exclusividade de alguns bancos ou bandeiras de cartão, pode requerer que alguns dados pessoais sejam compartilhados com estas empresas com o objetivo de agilizar o processo de compra e aumentar a segurança das transações.
            </p>
            <p>
            MUDANÇAS NA POLÍTICA DE PRIVACIDADE
            As ferramentas da Ta na Mão - Ingressos estão em constante evolução e pode ser necessário atualizar esta política. A cada mudança nessa política de privacidade atualizaremos a data da última modificação, disponível no começo da página e a continuidade na utilização dos serviços da Ta na Mão - Ingressos indicará consentimento com as novas políticas.
            </p>
            <p>
            DE ACORDO
            Ao utilizar nosso site e aplicativos, você concorda com a Política de Privacidade contida neste documento.
            </p>
            <p>
            Outras importantes informações sobre os termos e condições de utilização estão disponíveis em Políticas ou em nossa Central de Ajuda, se tiver alguma dúvida, não hesite entrar em contato.
            </p>
            </div>
        </div>
    );
}