import React, { useState, useRef } from "react";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";

import api from './../../services/api';
import Load from '../load';

import "./styles.css";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(254, "Máximo 254 caracteres")
    .required("* Nome é obrigatório"),
  email: Yup.string()
    .email("Digite um e-mail válido")
    .max(254, "Máximo 254 caracteres")
    .required("* E-mail é obrigatório"),
  subject: Yup.string()
    .max(254, "Máximo 254 caracteres")
    .required("* Assunto é obrigatório"),
  message: Yup.string()
    .max(1000, "Máximo 1000 caracteres")
    .required("* Mensagem é obrigatório"),
});

export default function Contato() {
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null)


  const handleSubmit = async (values) => {
    try {
        setLoading(true);
        const token = captchaRef.current.getValue();

        await api.post("/faleconosco", { ...values, token });
        alert('Mensagem enviada com sucesso');
    } catch(err) {
        console.log();
        if (err.response && err.response.status === 400) {
          alert(err.response.data.error);
        } else {
          alert("Houve um erro ao enviar a mensagem.");
        }
    } finally {
        setLoading(false);
        captchaRef.current.reset();
    }
  };

  return (
    <div className="pmeia_content">
      <div className="content">
        <h1>Fale Conosco!</h1>
        <Formik
          initialValues={{ name: "", email: "", subject: "", message: "" }}
          onSubmit={async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm({ name: "", email: "", subject: "", message: "" })
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const { handleSubmit } = props;

            return (
              <FormikForm onSubmit={handleSubmit}>
                <Field
                  type="text"
                  name="name"
                  placeholder="Nome"
                />
                <ErrorMessage
                  className="form-error"
                  component="span"
                  name="name"
                />

                <Field
                  type="text"
                  name="email"
                  placeholder="E-mail"
                />
                <ErrorMessage
                  className="form-error"
                  component="span"
                  name="email"
                />

                <Field
                  type="text"
                  name="subject"
                  placeholder="Assunto"
                />
                <ErrorMessage
                  className="form-error"
                  component="span"
                  name="subject"
                />

                <Field
                  type="text"
                  name="message"
                  placeholder="Mensagem"
                />
                <ErrorMessage
                  className="form-error"
                  component="span"
                  name="message"
                />

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                />

                <input type="submit" value="Enviar" />
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <Load loading={loading} />
    </div>
  );
}
