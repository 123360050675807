import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Parser from 'html-react-parser';

import './styles.css';

export default function AbadaTerms(props){

    const [agreeTerms, setAgreeTerms] = useState(false);
    // const [enableBuy, setEnableBuy] = useState(false);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
        if (bottom) { 
            setAgreeTerms(true);
        } else {
            setAgreeTerms(false);
        }
    }
    

    return (
        <div onScroll={handleScroll}>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="abada-terms"
            >
                <DialogTitle>
                    { props.evento.title_terms }
                </DialogTitle>
                <DialogContent >
                    <div style={{ margin: '10px' }}>
                        { Parser(props.evento.text_terms || '') }
                        <button 
                            type="button" 
                            className="btn_finalizarcompra" 
                            disabled={ !agreeTerms }
                            onClick={() => props.agreeTerms(true)}
                        >
                            <span style={{ marginLeft: 5 }}>Li os temos e quero comprar</span>
                        </button>
                    </div>    
                </DialogContent>
            </Dialog>
        </div>
    );
}