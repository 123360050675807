import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Load from '../../load';

import Menu from '../menu';
import './styles.css';
import TicketPrint from './ticketprint';

import api from '../../../services/api';

import ReactToPrint from 'react-to-print'; 
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

export default function MeusIngressosDetalhes(){

    let { id } = useParams();
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [controlNumber, setControlNumber] = useState("000");
    const [mostrarDialog, setMostrarDialog] = useState(false);
    let componentRef = useRef();

    useEffect( () => {      
        if (firstLoad) {
            loadTicket();
        }
    });

    const loadTicket = async () => {
        setLoading(true);
        try {
            const response = await api.get(`clients/tickets/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTicket(response.data);
            setControlNumber(response.data.control_number);
            setMostrarDialog(response.data.status !== 'NOVO');
        } catch(err) {
            console.log(err);
            alert('Erro ao carregar dados do ingresso.');
        } finally {
            setLoading(false);
            setFirstLoad(false);
        }
    }

    const podeImprimir = () => {
        return ticket && ticket.status === 'NOVO';
    };
    
    const naoPodeImprimir = () => {
        return !podeImprimir();
    };

    const ticketStatus = () => {
        if (ticket && ticket.status) {
            return ticket.status.replaceAll('_', ' ');
        } else {
            return '--';
        }
    }

    return (
        <div className="area_events_admin">
            <div className="content_client_geral">
                <Menu />
                <div className="content_client grey_accent">
                    <h1>Detalhes do ingresso N° { (podeImprimir() && ticket) ? ticket.control_number : '----' }</h1>
                    <ReactToPrint
                        documentTitle='Ingresso'
                        onBeforePrint={() => {
                            setLoading(true)
                        }}
                        onAfterPrint={() => {
                            setLoading(false)
                        }}
                        trigger={()=>{
                            return (
                                <button 
                                    disabled={naoPodeImprimir()}
                                    className="ticket-details-print-button">IMPRIMIR INGRESSO</button>
                            );
                        }}
                        content={()=>componentRef}
                    />
                    <TicketPrint ticket={ticket} controlNumber={controlNumber} ref={(el) => (componentRef = el)} />
                </div>
            </div>
            <Load loading={loading} />
            <Dialog
                open={mostrarDialog}>
                <DialogTitle>
                    Alerta
                </DialogTitle>
               <DialogContent>
                <DialogContentText>
                    Ingresso não disponivel para impressão. Ingresso com status { ticketStatus() }
                </DialogContentText>
               </DialogContent>
            </Dialog>
        </div>
    );
}
