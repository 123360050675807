import React from 'react';

import './styles.css';

export default function PMeia(){
    return (
        <div className="pmeia_content">
            <div className="content">
                <h1>A meia entrada mudou?</h1>
                <p>                
                Meia entrada conforme Lei nº 12.933 de 26 de dezembro de 2013 e Decreto 8.537, de 5 de dezembro de 2015.
                De acordo com a lei federal nº12.933, em vigência desde 01/12/2015, para ter acesso ao benefício da meia entrada você deve apresentar a CIE – Carteira de Identificação Estudantil, que deve conter:
                </p>
                <p>
                Nome completo e data de nascimento;
                Foto;
                Grau de escolaridade e nome da instituição de ensino na qual o estudante esteja matriculado;
                Data de validade até o dia 31 de março do ano subsequente ao de sua expedição;
                Certificação digital.
                ...
                Não serão aceitos boletos bancários, declaração de matrícula e carteirinhas fora do padrão acima.
                </p>
                <p>
                *A comprovação da meia entrada deverá ser apresentada no ato da compra e no dia do evento.
                </p>
                <p>
                Entra em vigor em 01/12/2015 decreto nº 8.537 de 5 de outubro de 2015 que regulamenta a nova lei da meia-entrada (Lei n° 12.933/2013) e o Estatuto da Juventude (Lei n° 12.852/2013), garantem que 40% dos ingressos de um evento sejam destinados à meia entrada.
                </p>
                <p>
                Consulte também:
                <br/>
                <a href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933" target="_blank" rel="noopener noreferrer">www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933</a>
                </p>
            </div>
        </div>
    );
}