import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from './authenticate';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        { ...rest }
        render={ props => 
            isAuthenticated() ? (
                <Component { ...props } />
            ) : (
                <Redirect to={{ pathname: "/cliente/login", state: { from: props.location } }} />
            )
        }
    />
);
