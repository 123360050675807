import React from 'react';

import './styles.css';

export default function Sobre(){
    return (
        <div className="psobre_content">
            <div className="content">
                <h2>Sobre O Ta Na Mão -Ingressos do seu jeito!</h2>
                <p>                
                    Página em construção, em breve nossa história sobre o Ta Na Mão - Ingressos do seu jeito!!!
                </p>
                <p>

                </p>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        </div>
    );
}