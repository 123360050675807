import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import Menu from "../menu";
import api from "../../../services/api";

import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import Pagamento from "../pagamento";
import { transformStatus, getStatusClass, valorTotal, podeEfetuarPagamento, podePagarNoPix, valorTotalCurrency, valorParcela, pixVencido } from "./pedido_utils";

import { FiRefreshCcw } from 'react-icons/fi';

import "./styles.css";
import Load from "../../load";

export default function DetalhesPedido() {
  let { id } = useParams();
  const [order, setOrder] = useState({});
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [podePagar, setPodePagar] = useState(false);
  const [iniciou, setIniciou] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Carregando...');

  useEffect(() => {
    if (!iniciou) {
      loadOrderData();
      const idFieldSessioId = 'tanamao_session_id';
      const script = document.createElement("script");
      const clientId = process.env.REACT_APP_CLEARSALE_APP;
      script.innerHTML = `try{(function (a, b, c, d, e, f, g) {a['CsdpObject'] = e; a[e] = a[e] || function () {(a[e].q = a[e].q || []).push(arguments)}, a[e].l = 1 * new Date(); f = b.createElement(c),g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)})(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');csdp('app', '${clientId}');csdp('outputsessionid', '${idFieldSessioId}')['${idFieldSessioId}'];}catch(err){}`;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
        document.getElementById(idFieldSessioId).value = '';
      };
    }
  });

  const loadOrderData = async () => {
    setIniciou(true);
    try {
      showLoading();
      const response = await api.get(`/clients/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderData = response.data;
      setOrder(orderData);
      orderData.transactions.reverse();

      setPodePagar(podeEfetuarPagamento(orderData) && (orderData.form_payment === 'CREDIT_CARD' || podePagarNoPix() || (orderData.form_payment === 'PIX' && pixVencido(orderData))));
    } catch(err) {
      alert('Erro ao carregar os dados do pedido.')
    } finally {
      dismissLoading();
    }
  };

  function transformDate(data, formatData) {
    if (!data) {
      return "";
    }
    var dateAt = parseISO(data);
    if (!formatData) {
      formatData = "dd 'de' MMMM ' às ' HH:mm 'hrs' ";
    }
    return format(dateAt, formatData, { locale: pt });
  }

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshData = async () => {
    try {
      showLoading();
      await loadOrderData();
    } catch (err) {
      alert('Erro ao recarregar.');
    } finally {
      dismissLoading();
    }
  };

  const showLoading = (message) => {
    setLoading(true);
    if (!message) message = 'Carregando...';
    setLoadingMessage(message);
  };

  const dismissLoading = () => {
    setLoading(false);
    setLoadingMessage('Carregando...');
  };

  const temDadosPagamentoCartao = () => {
    return order.card_first_digits && order.card_last_digits && order.card_holder && order.card_brand
  }

  const temDadosPagamentoPix = () => {
    return order.pix_end_to_end_id && order.pix_holder_name && order.pix_holder_tax_id
  }

  const copiar = () => {
    setTimeout(() => alert('Copiado para aréa de transferência'), 300);
    navigator.clipboard.writeText(order.pix_text);
  }

  return (
    <div className="area_events_admin">
      <div className="content_client_geral">
        <Menu />
        <div key={id} className="content_client grey_accent">
          <Pagamento
            eventId={order != null && order.tickets != null ? order.tickets[0].event.id : null}
            orderId={order.id}
            disablePIX={order.form_payment === 'PIX'}
            valorTotal={valorTotal(order)}
            open={open}
            onClose={() => {
              handleClose();
              loadOrderData();
            }}
            onComplete={() => {
              loadOrderData();
            }}
          />
          <Load loading={loading} message={loadingMessage} />
          <div className="content_client grey_accent">
            <h1>Detalhes do Pedido N° #{order.number}</h1>
            <div className="order-details-content">
              <div className="order-details-inner">
                <span
                  className={"order-status " + getStatusClass(order.status)}
                >
                  {transformStatus(order.status)}
                </span>
                {
                  podePagarNoPix(order) ?
                    <div className='container-method-payments'>
                      <h4>Escanei o QRCode abaixo ou copie o texto do pagamento</h4>
                      <div className='container-method-payments-options'>
                        <img src={order.pix_link_qr_code} alt='QRCode do PIX' height="150" width="150" />
                        <div className='container-pix-text'>
                          <span>{ order.pix_text }</span>
                          <button type='button' onClick={() => copiar()} style={{
                            padding: '10px 30px',
                            margin: '5px',
                          }} >
                            Copiar
                          </button>
                          <div className='text-copiar'>
                            Copie o código e cole no aplicativo do seu banco para efetuar o pagamento com PIX
                          </div>
                          <div className='text-info'>
                            Após o pagamento aguarde até 5 minutos para a confirmação.
                          </div>
                        </div>
                      </div>
                    </div>
                  : null
                }
                <div className="order-number">
                  <span className="label-item">Número do pedido: </span>
                  <span className="item-data">{order.number}</span>
                </div>
                <div className="order-date">
                  <span className="label-item">Data do pedido: </span>
                  <span className="item-data">
                    {" "}
                    {transformDate(order.order_at)}{" "}
                  </span>
                </div>
                <div className="order-payment-form">
                  <span className="label-item">Forma de pagamento:</span>
                </div>
                <div className="order-payment-form">
                  <span className="item-data"> {order.form_payment === 'PIX' ? 'PIX' : 'Cartão de crédito'} </span>
                </div>
               {
                temDadosPagamentoCartao() ?
                  (
                    <div>
                      <div className="order-payment-form">
                        <span className="item-data"> {order.card_brand} </span>
                      </div>
                      <div className="order-payment-form">
                        <span className="item-data"> {order.card_first_digits + ' ******** ' + order.card_last_digits} </span>
                      </div>
                      <div className="order-payment-form">
                        <span className="item-data"> {order.card_holder} </span>
                      </div>
                    </div>
                  )
                : temDadosPagamentoPix() ? 
                (
                  <div>
                    <div className="order-payment-form">
                      <span className="item-data"> {order.pix_holder_name} </span>
                    </div>
                    <div className="order-payment-form">
                      <span className="item-data"> {order.pix_holder_tax_id} </span>
                    </div>
                    <div className="order-payment-form">
                      <span className="item-data"> {order.pix_end_to_end_id} </span>
                    </div>
                  </div>
                )
                : null
               }
                <div className="order-tickets">
                  <span className="label-item">Ingressos: </span>
                  <ul className="item-data tickets">
                    {order.tickets
                      ? order.tickets.map((ticket) => {
                          return (
                            <li key={ticket.id}>
                              {ticket.event.name} - {ticket.ticketType.name} (
                              {ticket.ticketType.type})

                              {
                                (order.status === 'PAGO' && ticket.status === 'NOVO')
                                ? <Link to={`/cliente/meus_ingressos/detalhes/${ticket.id}`} >
                                  Visualizar/Imprimir 
                                </Link>
                                : null
                              }
                              
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                <div className="order-tickets">
                  <div className="order-label-menu">
                    <span className="label-item">Transações: </span>
                    <FiRefreshCcw className="icon-refresh" onClick={() => refreshData()}/>
                  </div>
                  <div className="item-data tickets">
                    <table className="table table-striped" style={{ width: '100%'  }}>
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Mensagem</th>
                          <th>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.transactions
                          ? order.transactions.map((transaction) => {
                              return (
                                <tr key={transaction.id}>
                                  <td
                                    style={
                                      transaction.type === "SUCCESS"
                                        ? { color: "green" }
                                        : { color: "red" }
                                    }
                                  >
                                    {transaction.type === "SUCCESS"
                                      ? "Sucesso"
                                      : "Erro"}
                                  </td>
                                  <td>{transaction.message}</td>
                                  <td>
                                    {transformDate(
                                      transaction.transaction_at,
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="order-total-price">
                  { Number(order.total) > 0  ? <span className="item-data price">{ order.installments }x de { valorParcela(order) } </span> : null }
                </div>
                <div className="order-total-price">
                  <span className="label-item">Valor total: </span>
                  <span className="item-data price">
                    {order.tickets ? valorTotalCurrency(order) : null}
                  </span>
                </div>
                
                {podePagar ? (
                  <form onSubmit={(e) => handleClickOpen(e)}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <input type="submit" value={pixVencido(order) ? "Efetuar Pagamento no crédito" : "Efetuar Pagamento"} />
                    </div>
                  </form>
                ) : null}
              </div>
              
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
  );
}
