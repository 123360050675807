import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';

import Menu from './../menu';
import api from '../../../services/api';

import './styles.css';

const validationSchema = Yup.object().shape({
  zip_code: Yup.string()
    .test("len", "CEP inválido", (val) => {
      if (val == null) return false;
      const val_length_without_dashes = val.replace(/\D/g, "").length;
      return val_length_without_dashes === 8;
    })
    .required("* CEP obrigatório"),
  street: Yup.string().min(4, "Mínimo 4 caracteres").max(160, "Máximo 160 caracteres").required("* Logradouro obrigatório"),
  state: Yup.string().min(2, "Mínimo 2 caracteres").max(2, "Máximo 2 caracteres").required("* Estado obrigatório").test(
    'estadoValido',
    'Estado inválido',
    (item)=> {
      return estados.includes(item.toUpperCase());
    }
  ),
  city: Yup.string().min(2, "Mínimo 2 caracteres").max(90, "Máximo 90 caracteres").required("* Cidade obrigatório"),
  number: Yup.string().min(1, "Mínimo 2 caracteres").max(20, "Máximo 20 caracteres").required("* Número obrigatório"),
  locality: Yup.string().min(2, "Mínimo 2 caracteres").max(60, "Máximo 60 caracteres").required("* Bairro obrigatório"),
  complement: Yup.string().min(4, "Mínimo 4 caracteres").max(40, "Máximo 40 caracteres"),
  country: Yup.string().min(3, "Mínimo 3 caracteres").max(3, "Máximo 3 caracteres").required("* Pais obrigatório"),
});

const estados = ['AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'DF'];

export default function BillingAddress(props) {

  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const token = localStorage.getItem('token');
  const [address, setAddress] = useState({});
  

  useEffect(() => {
    api.get(`authenticate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setAddress(response.data.billingAddress || {});
    }).catch(err => {
      console.log(err)
    });
  }, [token]);

  const initialValues = {
    zip_code: address.zip_code || '',
    street: address.street || '',
    state: address.state || '',
    city: address.city || '',
    number: address.number || '',
    locality: address.locality || '',
    complement: address.complement || '',
    country: address.country || 'BRA',
  };

  const checkCep = (e, setFieldValue) => {
    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length !== 8) {
      return;
    }
    setLoadingCep(true);

    fetch('https://viacep.com.br/ws/' + cep + '/json/')
      .then(res => res.json()).then(data => {
        setFieldValue("street", data.logradouro);
        setFieldValue("city", data.localidade);
        setFieldValue("locality", data.bairro);
        setFieldValue("state", data.uf);
        setFieldValue("complement", data.complemento);
        setLoadingCep(false);
      });

  }

  return (
    <div className="area_events_admin">
      <div className="content_client_geral">
        <Menu />
        <div className="content_client">
          <h1>Endereço de cobrança</h1>
          <center>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true} 
              onSubmit={
                async (values) => {
                  try {
                    setSaving(true);
                    const response = await api.post(`clients/billing-address`,
                      values, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      }
                    });
                    if (response) {
                      alert('Endereço salvo com sucesso!');
                      const query = new URLSearchParams(props.location.search);
                      const pagamentoEmAndamento = query.get('pagamentoEmAndamento') === 'true';
                      if (pagamentoEmAndamento) {
                        history.push('/cliente/carrinho');
                      }
                    }

                  } catch (err) {
                    alert(err.response.data.error);
                  } finally {
                    setSaving(false);
                  }
                }
              }
              validationSchema={validationSchema}
            >
              {
                props => {
                  const { handleSubmit, setFieldValue, isValid } = props;

                  return (
                    <FormikForm className="Form" onSubmit={handleSubmit}>
                      <Field type="text" className="Form-Field fadeIn third" name="zip_code" placeholder="CEP" disabled={loadingCep || saving} maxLength="8" onKeyUp={(e) => checkCep(e, setFieldValue)} />
                      <ErrorMessage className="form-error" component="span" name="zip_code" />

                      {loadingCep ? <CircularProgress size={20} /> : null}

                      <Field type="text" className="Form-Field fadeIn third" name="street" placeholder="Logradouro" disabled={loadingCep || saving} maxLength="255" />
                      <ErrorMessage className="form-error" component="span" name="street" />
                      
                      <Field type="text" className="Form-Field fadeIn third" name="number" placeholder="Número" disabled={loadingCep || saving} maxLength="255" />
                      <ErrorMessage className="form-error" component="span" name="number" />
                      
                      <Field type="text" className="Form-Field fadeIn third" name="locality" placeholder="Bairro" disabled={loadingCep || saving} maxLength="255" />
                      <ErrorMessage className="form-error" component="span" name="locality" />

                      <Field type="text" className="Form-Field fadeIn third" name="complement" placeholder="Complemento" disabled={loadingCep || saving} maxLength="255" />
                      <ErrorMessage className="form-error" component="span" name="complement" />

                      <Field type="text" className="Form-Field fadeIn third" name="city" placeholder="Cidade" disabled={loadingCep || saving} maxLength="255" />
                      <ErrorMessage className="form-error" component="span" name="city" />

                      <Field type="text" className="Form-Field fadeIn third" name="state" placeholder="Estado" disabled={loadingCep || saving} maxLength="2" />
                      <ErrorMessage className="form-error" component="span" name="state" />

                      <div></div>
                      <input 
                        type="submit" 
                        className="fadeIn fourth" 
                        name="Salvar" 
                        value={saving ? 'Savando...' : 'Salvar'} 
                        disabled={loadingCep || saving || !isValid } />
                    </FormikForm>
                  );
                }
              }
            </Formik>

          </center>
        </div>
      </div>
    </div>
  );
}