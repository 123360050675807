import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { FiFilm, FiShield, FiHelpCircle } from 'react-icons/fi';
import { Fade } from 'react-slideshow-image';

import './styles.css';

import api from '../../services/api';
import Events from '../events/index';

export default function Home(){

    const [ events, setEvents] = useState([]);

    useEffect(()=>{
        api.get('site/events').then(
            response => {
                setEvents(response.data);
            }
        );
    }, []);

    const eventWithBanners = () => {
        return events.filter((e) => e.image_banner);
    };

    const fadeProperties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: eventWithBanners().length > 1,
        indicators: eventWithBanners().length > 1,
        onChange: (oldIndex, newIndex) => {
          //console.log(`fade transition from ${oldIndex} to ${newIndex}`);
        }
    }


    return (
        <div className="home">
            <div className="home_content">
                <Fade { ...fadeProperties }>
                        {/*eventWithBanners()*/events.map( event => {
                            return event.image_banner != null ? (
                                <div className="slide_show_image" key={event.id}>
                                    
                                    <Link to={'/evento/'+event.id} className="each-slide">
                                        <div className="image_slide" style={{'backgroundImage': `url(${event.image_banner})`}}>
                                        {/* <span>{ event.name }</span> */}
                                        </div>
                                    </Link>
                                    
                                </div>
                                
                            ) : (
                                <div className="slide_show_image informativo-ta-na-mao" key="" >

                                    <Link to="/" className="each-slide">
                                        <div className="image_slide"></div>
                                    </Link>

                                </div>
                            );
                          
                        })}                        
                </Fade>    

                <Events />

                <div className="clear">&nbsp;</div>
                <div className="footer_home">
                    <div className="meia_descontos">
                        <Link to="/politica/meia_entrada">
                            <FiFilm /><br/>
                            <span >MEIA ENTRADA E DESCONTOS</span>

                        </Link>                        
                    </div>
                    <div className="meia_descontos">
                        <Link to="/politica/privacidade">
                            <FiShield /><br/>
                            <span >SEGURANÇA E PRIVACIDADE</span>
                        </Link>
                    </div>
                    <div className="meia_descontos">
                        <a href="/duvidas_frequentes">
                            <FiHelpCircle /><br/>
                            <span >DÚVIDAS FREQUENTES</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}