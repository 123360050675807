import React, { useEffect, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { StoreContext } from '../store';

import { format, parseISO }  from 'date-fns';
import { FiCalendar } from 'react-icons/fi';
import  pt  from 'date-fns/locale/pt-BR';
import { Link } from 'react-router-dom';

import SALE_DISABLED from  './../event/evento_a_vender'

import api from '../../services/api';
import './styles.css';

export default function Events(){

    const history = useHistory();
    const [ events, setEvents ] = useState([]);
    const storeContext = useContext(StoreContext);

    useEffect(()=>{
        api.get('site/events').then(
            response => {
                setEvents(response.data)
            }
        );
        
    }, []);

    const naoPodeComprar = (event) => {
        const eventId = storeContext.eventId;
        return !vendaHabilitada(event) || (eventId !=null &&  Number(eventId) !== Number(event.id));
    }

    const vendaHabilitada = (event) => {
        return event.active_online_sale && !SALE_DISABLED;
    }

    const comprar = (event) => {
        history.push('/evento/'+event.id);
    }
    
    return (
        <div className="list_events">
            <div className="cards cards_events">
                <ul>
                    {events.map(event => {
                        var eventDate = parseISO(event.start_at);
                        //console.log(event.name);
                        var formattedEventDate = format(
                            eventDate,
                            "'Dia' dd 'de' MMMM', às ' HH:mm 'hrs' ",
                            {locale: pt}
                        );
                        //alert(eventDate);

                        return <li className="items_event" key={event.id}>
                                    <Link to={'/evento/'+event.id} className="image_event_link" >
                                        <img src={event.image} alt={event.name}/>
                                    </Link>
                                    <Link to={'/evento/'+event.id}>
                                        <h3 style={{minHeight: 43}}>{event.name.length > 53 ? event.name.substring(0,53)+"..." : event.name}</h3>
                                    </Link>
                                    <hr />
                                    <div className="event-local-and-date">
                                        <span className="event_local" style={{fontSize: 15}}><b>Local:</b> {event.place_of_establishment}</span>
                                        <p className="event_date">
                                            <FiCalendar />
                                            {formattedEventDate}
                                        </p>
                                    </div>
                                    { 
                                        vendaHabilitada(event) 
                                        ? 
                                            <div>
                                                <button 
                                                    title={naoPodeComprar(event) ? "Há ingresso de outro evento no carrinho" : "Comprar"}
                                                    type='button' 
                                                    className='ta-btn' 
                                                    disabled={naoPodeComprar(event)}
                                                    onClick={naoPodeComprar(event) ? null :() => comprar(event)}>Comprar</button>
                                                    {
                                                        naoPodeComprar(event) ? <span style={{
                                                            textAlign: 'center',
                                                            display: 'flex'
                                                        }}>Venda desabilitada pois há ingresso(s) de outro evento no carrinho!</span> : null
                                                    }
                                            </div>
                                        : null }
                                </li>
                    })}
                </ul>
            </div>
        </div>
    );
}