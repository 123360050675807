import React, { useState } from 'react';

import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import Yup from './../../../../utils/yup';
import { FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import './styles.css';

export default function PasswordRedefinition(props) {

    const history = useHistory();
    const { token } = props.match.params;
    const [loading, setLoading] = useState(false);

    //console.log(token);

    const initialValues =  {
        token: token,
        password: ""
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().min(6, "Mínimo de 6 caracteres").required("* Senha obrigatória"),
        password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Confirmação difere da Senha')
                .required("* Confirmação da Senha obrigatória"),
    });

    const handleSubmit = async (values) => {
        const { token, password } = values;

        try {
            setLoading(true);
            const response = await api.post(
                'clients/reset/password', 
                { 
                    token,
                    password
                });
            if(response){
                alert('Sucesso! \n\nVocê acaba de redefinir \na sua senha!');
                setTimeout(() => history.push('/cliente/login'), 1000)
            }
        } catch (err) {
            alert( err.response.data.error ); 
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="wrapper fadeInDown lost-password-container">
            <div id="formContent" >
                <div className="fadeIn first">
                    <FiUser />
                    <span > Tá na Mão - Redefinição de senha!</span>
                </div>
                
                <Formik 
                    initialValues={initialValues} 
                    onSubmit={handleSubmit} 
                    validationSchema={validationSchema} >
                    { props => {
                        const { handleSubmit } = props;

                        return (
                            <FormikForm className="Form" onSubmit={handleSubmit}>
                                    
                                <Field type="password" id="password" className="fadeIn third" name="password" placeholder="Senha" />
                                    <ErrorMessage className="form-error" component="span" name="password" />

                                <Field type="password" id="password_confirmation" className="fadeIn third" name="password_confirmation" placeholder="Confirmação da Senha" />
                                    <ErrorMessage className="form-error" component="span" name="password_confirmation" />

                                
                                <input 
                                    type="submit" 
                                    className="fadeIn fourth" 
                                    name="Redefinir" 
                                    value={loading ? "Redefinindo senha..." : "Redefinir Senha"}
                                    disabled={loading} />
                            
                            </FormikForm>
                        );
                    }}
                    
                </Formik>
            </div>
        </div>

    );
}
