import React, { useState, useRef } from 'react';

import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import Yup from './../../../utils/yup';
import { FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";

import api from '../../../services/api';
import './styles.css';

export default function LostPassword() {

    const history = useHistory();
    const [sending, setSending] = useState(false);
    const captchaRef = useRef(null);

    const initialValues =  {
        email: ""
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Digite um email válido").required("* Email obrigatório")
    });

    const handleSubmit = async (values) => {
        const { email } = values;

        try {
            setSending(true);
            const token = captchaRef.current.getValue();
            const response = await api.post(
                'clients/forgot/password', 
                { 
                    email,
                    token
                });
            if(response){
                alert('Sucesso! \n\nFoi enviado um email \npara você resetar sua senha!');
                history.push('/');
            } else {
                alert('Houve um erro ao enviar o e-mail de redefinição de senha');
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                alert(err.response.data.error);
            } else {
                alert('Falha ao tentar fazer o solicitar o link de resete de senha');
            }
        } finally {
            setSending(false);
            captchaRef.current.reset();
        }
    };

    return (
        <div className="wrapper fadeInDown lost-password-container">
            <div id="formContent" >
                <div className="fadeIn first">
                    <FiUser />
                    <span > Tá na Mão - Esqueci minha senha!</span>
                </div>
                
                <Formik 
                    initialValues={initialValues} 
                    onSubmit={handleSubmit} 
                    validationSchema={validationSchema} >
                    { props => {
                        const { handleSubmit } = props;

                        return (
                            <FormikForm className="Form" onSubmit={handleSubmit}>
                                    
                                <Field type="text" className="fadeIn third" name="email" placeholder="Digite seu email" />
                                    <ErrorMessage className="form-error" component="span" name="email" />
                                
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        ref={captchaRef}
                                        />
                                </div>
                                
                                <input 
                                    type="submit" 
                                    className="fadeIn fourth" 
                                    name="Redefinir" 
                                    value={sending ? "Enviando e-mail de redefinição..." : "Enviar redefinição de senha"}
                                    disabled={sending} />
                            
                            </FormikForm>
                        );
                    }}
                    
                </Formik>
            </div>
        </div>

    );
}
