import React from 'react';

import './App.css';

import Routes from './routes';

function App() {
  return (

      <Routes />

  );
}

export default App;
