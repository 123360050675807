import React from 'react';

import './styles.css';

export default function PVendas(){
    return (
        <div className="pvendas_content">
            <div className="content">
            <div class="column">
            <h1>POLÍTICAS DE VENDAS</h1>
            <p>
                Antes de finalizar sua compra conheça as políticas e condições de venda do TáNaMão Ingressos:
            </p>
            <p>
                1. Melhor lugar disponível: ao comprar pela Internet, você pode escolher o melhor lugar disponível de acordo com sua preferência, simultaneamente, como se estivesse na própria bilheteria do evento. Na impossibilidade de escolha dos lugares, o sistema selecionará automaticamente “os melhores lugares disponíveis juntos” no momento da sua compra, para o setor escolhido.
            </p>
            <p>
                2. A compra estará sujeita à disponibilidade de ingressos e à aprovação da operadora de seu cartão de crédito.
            </p>
            <p>
                3. Ao comprar em nosso site ou ponto de venda sua compra poderá ficar sujeita à análise e aprovação do nosso Departamento de Segurança. Analisando caso a caso, documentos poderão ser solicitados ao titular da compra, que deverá encaminhá-los ao e-mail indicado e dentro do prazo estipulado, para finalização e entrega do pedido, sob pena de cancelamento.
            </p>
            <p>
                3.1 A fim de facilitar a análise do Departamento de Segurança e garantir a autenticidade de sua compra, preencha todos os campos do cadastro, em especial nome completo, número de CPF, e-mail, telefone para contato e endereço.
            </p>
            <p> 
                3.2 Taxas e encargos poderão incidir sobre os ingressos adquiridos através dos canais de venda do TáNaMão (telefone, internet e pontos de venda).
            </p>
            <p>
                3.2.1. Todo Evento possui bilheteria oficial, sem incidência de taxas e encargos. Consulte a bilheteria de cada Evento, na página de vendas do próprio Evento.
            </p>
            <p>
                3.3 Respeitamos a lei estadual 6103/2011 que dispõe sobre a regulamentação da cobrança de taxa de conveniência no estado do Rio de Janeiro. Para ler a lei na íntegra, clique aqui. (Verificar se tem alguma lei estadual no PI para esses casos).
            </p>
            <p>
                3.4. A Empresa comercializa seus ingressos de forma exclusiva. Para a segurança de nossos Clientes, recomendamos que a compra dos ingressos seja feita somente pelos canais oficiais de compra, disponíveis neste Website.
            </p>
            <p>
                3.5. Não nos responsabilizamos por ingressos adquiridos em canais de venda não oficiais, seja através de pessoa física, pessoa jurídica, outros Websites e comunidades na Internet, com quem não possuímos qualquer vínculo.
            </p>
            <p>
                4. Confira atentamente os dados de seu pedido (espetáculo, dia, horário, quantidades, setor) antes da confirmação de sua compra. Não serão permitidas troca ou devolução de ingressos. O seu ingresso é um produto único, ou seja, após sua compra ele não estará mais disponível para venda.
            </p>
            <p>
                4.1. Em caso de arrependimento do cliente, o reembolso do valor da compra será efetuado, desde que esse direito seja requisitado em até 07 (sete) dias da data da compra, e que seja em até 48 (quarenta e oito) horas antes da data do espetáculo ou evento, sendo assim, para compras realizadas próximo da data do evento, os ingressos só poderão ser cancelados em até 48 (quarenta e oito) horas antes da data do evento.
                <br/>
                <a href="https://forms.gle/nKQx451Bc61FpCwQ8" target="_blank" rel="noopener noreferrer">
                    Formulário de Cancelamento
                </a>
            </p>
            <p>
                4.2. Para ingressos adquiridos em ponto de venda e bilheteria, o estorno do valor pago somente será efetuado após a apresentação física dos mesmos nos pontos de venda do TáNaMão. Depois de confirmada a autenticidade do ingresso, a devolução dos valores pagos seguirá o procedimento abaixo descrito, de acordo com a forma de pagamento utilizada:
            </p>
            <p>
                4.2.1 Cartão de crédito: o estorno do valor será solicitado à operadora do cartão, que disponibilizará o crédito em fatura seguinte ou subsequente.
            </p>
            <p>
                4.2.2 Cartão de débito: o estorno do valor será solicitado à operadora do cartão utilizado na compra, que creditará o valor em conta bancária vinculada ao cartão, em até 15 dias úteis.
            </p>
            <p>
                4.2.3 Dinheiro: depósito bancário em até 20 dias úteis.
            </p>
            <p>
                4.2.4 Informamos que os prazos discriminados nos itens 4.2.1 e 4.2.2, para estornos via cartão, crédito ou débito, podem variar, de acordo com o fechamento de sua fatura e/ou seu banco emissor.
            </p>
            <p>
                5. Meia-Entrada: a compra de meia-entrada e/ou outros descontos estará sujeita a regras do produtor de cada evento ou casa de espetáculo.
            </p>
            <p>
                5.1. Estudantes: Carteira expedida por estabelecimento de ensino fundamental, médio, superior ou pela respectiva associação e ou agremiação. Atenção: conforme disposto no § 2o do Art. 3° do Decreto n. 8.537/2015, deverão constar os seguintes elementos na Carteira de Identificação Estudantil: I - nome completo e data de nascimento do estudante; II - foto recente do estudante; III - nome da instituição de ensino na qual o estudante esteja matriculado; IV - grau de escolaridade; e V - data de validade até o dia 31 de março do ano subsequente ao de sua expedição. Os documentos apresentados na compra deverão ser apresentados na entrada do espetáculo. Lembramos que os descontos de 50% não se aplicam à cursos livres: inglês, informática ou pré-vestibular. Para sua maior segurança, os ingressos não serão vendidos a outra pessoa, mesmo que esteja de posse dos seus documentos.
            </p>
            <p>
                5.2. Em algumas situações a meia-entrada somente será vendida na bilheteria do evento mediante apresentação do documento comprobatório pelo próprio cliente. Confira as regras de cada estabelecimento nos respectivos sites. Alguns eventos autorizam a venda de meia-entrada através de todos os canais de venda, sendo obrigatória a apresentação do documento no momento da compra e/ou no momento da entrada do evento. Neste caso, o sistema TáNaMão estará realizando o cadastro de sua compra, na qual somente será permitida a aquisição de 01 ingresso com desconto por documento para o mesmo evento. No caso de não apresentação do documento comprobatório na entrada do evento, o produtor se reserva o direito de solicitar o pagamento do valor de complemento do ingresso referente ao preço de inteira.
            </p>
            <p>
                TáNaMão - Ingressos do seu jeito.
                Rua Almirante Gervásio Sampaio, 554 - Centro, Parnaíba - PI, 64200-250
            </p>

            </div>                
            </div>
        </div>
    );
}