import React, { useState, useEffect, useContext, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { FiUser } from 'react-icons/fi';
import api from '../../../services/api';
import { StoreContext } from "../../store";

import ReCAPTCHA from "react-google-recaptcha";

import './styles.css';

export default function Login(){

    const history = useHistory();

    const [ login, setLogin ] = useState("");
    const [ loggingIn, setLoggingIn ] = useState(false);
    const [ password, setPassword ] = useState("");
    const storeContext = useContext(StoreContext);
    const captchaRef = useRef(null)

    useEffect(()=>{
        if(localStorage.getItem('token') != null){
            history.push('/cliente/pedidos');
        }
    });

    async function userLogin(e){
        e.preventDefault();
        try {
            setLoggingIn(true);

            const token = captchaRef.current.getValue();
            
            const response= await api.post('/clients/authenticate', { login, password, token });

            localStorage.setItem('userId', response.data.id);
            localStorage.setItem('userName', response.data.name);
            localStorage.setItem('token', response.data.token);

            storeContext.setToken(response.data.token);
            storeContext.setUserId(response.data.id);
            storeContext.setUserName(response.data.name);


            if(response.data.is_active){
                if(parseInt(localStorage.getItem("qntdTotal")) > 0){
                    history.push('/cliente/carrinho');
                }else{
                    history.push('/cliente/pedidos');
                }
            }else{
                localStorage.clear();
                history.push('/cliente/registrar/confirm_email');
            } 
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 400) {
                alert(err.response.data.error);
            } else {
                alert('Falha ao tentar fazer o Login, tente novamente!\nVerifique se está digitando o email e/ou senha corretamente');
            }
        } finally {
            setLoggingIn(false);
            if (captchaRef.current != null) {
                captchaRef.current.reset();
            }
        }
    }

    return (
        <div className="administration">
            <div className="administration_content">
         
                <div className="wrapper fadeInDown">
                    <div id="formContent">

                        <div className="fadeIn first">
                            <FiUser />
                            <span > Tá na Mão - Área do Cliente</span>
                        </div>

                        <form onSubmit={userLogin}>
                            <input type="text" value={login} onChange={ e => setLogin(e.target.value) } id="login" className="fadeIn second" name="login" placeholder="E-mail" />
                            <input type="password" value={password} onChange={ e => setPassword(e.target.value) } id="password" className="fadeIn third" name="password" placeholder="Senha" />
                            
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={captchaRef}
                                    />
                            </div>

                            <input type="submit" className="fadeIn fourth" value={loggingIn ? "Entrando..." : "Entrar"} disabled={loggingIn} />
                        </form>
                        <div className="sem_cadastro">
                            ou <Link to="/cliente/registrar">
                                Cadastre-se
                            </Link>
                            <br/><br/>
                        </div>
                        <div className="lost-password">
                            <p>
                                Esqueceu sua senha?  
                                <Link to="/cliente/lostpassword">
                                    <span style={{ marginLeft: 5 }}> Clique Aqui!</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}