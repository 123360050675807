import React from 'react';

import { Link } from 'react-router-dom';

export default function Menu(){
    return (
        <div className="menu_left_client">
            <ul>
                <li>
                    <Link to="/cliente/meus_dados">
                        Meus dados
                    </Link>
                </li>
                <li>
                    <Link to="/cliente/endereco-cobranca">
                        Endereço de cobrança
                    </Link>
                </li>
                <li>
                    <Link to="/cliente/carrinho">
                        Meu carrinho
                    </Link>
                </li>
                <li>
                    <Link to="/cliente/pedidos">
                        Meus pedidos
                    </Link>
                </li>
                <li>
                    <Link to="/cliente/meus_ingressos">
                        Meus ingressos
                    </Link>
                </li>
                {/* 
                <li>
                    <Link to="/cliente/certificacao_de_cadastro">
                        Certificação de cadastro
                    </Link>
                </li> */}
            </ul>
        </div>
    );
}