import { currencyFormat } from "../../../utils/currency-formatter";
import SALE_DISABLED from '../../event/evento_a_vender';

export const transformStatus = (status) => {
  switch (status) {
    case "AGUARDANDO_PAGAMENTO":
      return "Aguardando pagamento";
    case "PROCESSANDO_PAGAMENTO":
      return "Processando pagamento";
    case "EM_DISPUTA":
      return "Em Disputa";
    case "PAGO":
      return "Pago";
    case "ESTORNADO":
      return "Estornado";
    case "NEGADO":
      return "Pagamento negado";
    case "CANCELADO":
      return "Cancelado";
    case "ERRO_NA_TRANSACAO":
      return "Erro ao efetuar o pagamento";
    case "AGUARDANDO_CONFIRMACAO_PAGAMENTO":
      return "Aguardando confirmação do pagamento";
    default:
      return "";
  }
};

export const getStatusClass = (status) => {
  switch (status) {
      case 'AGUARDANDO_PAGAMENTO':
          return 'a-pagar';
      case 'PROCESSANDO_PAGAMENTO':
          return 'processando-pagamento';
      case 'AGUARDANDO_CONFIRMACAO_PAGAMENTO':
          return 'processando-pagamento';
      case 'EM_DISPUTA':
          return 'cancelado';
      case 'PAGO':
          return 'pago';
      case 'ESTORNADO':
          return 'estornado';
      case 'NEGADO':
          return 'a-pagar';
      case 'CANCELADO':
          return 'cancelado';
      case 'ERRO_NA_TRANSACAO':
          return 'a-pagar';
      default: return '';
  }
};

export const podePagarNoPix = (order) => {
  if (!order || !order.status) return false;
  return ["AGUARDANDO_PAGAMENTO", "ERRO_NA_TRANSACAO"].includes(order.status) 
    && (order.form_payment === 'PIX' && !pixVencido(order))
    && valorTotal(order) !== 0
}

export const pixVencido = (order) => {
  if (!order || !order.status) return false;
  let expiration = null;
  if (order.pix_expiration_date) {
    expiration = new Date(order.pix_expiration_date);
  }
  return expiration == null || expiration < new Date();
}

export const podeEfetuarPagamento = (order) => {
  if (!order || !order.status) return false;
  if (SALE_DISABLED) return false;
  
  return ["AGUARDANDO_PAGAMENTO", "ERRO_NA_TRANSACAO"].includes(order.status) 
    && valorTotal(order) !== 0
}

export const valorTotal = (order) => {
  if (order && order.tickets) {
    return order.tickets
      .map((ticket) => ticket.value)
      .reduce((cur, acc) => cur + acc, 0);
  }
  return 0;
};

export const valorTotalCurrency = (order) => {
  const total = Number(order.total);
  if (total > 0) {
    return currencyFormat(total);
  }
  return currencyFormat(valorTotal(order));
};


export const valorParcela = (order) => {
  const total = Number(order.total);
  if (total > 0) {
    return currencyFormat(total / (order.installments));
  } 
  return currencyFormat(valorTotal(order));
};