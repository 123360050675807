export const currencyFormat = (num) => {
  if (!num) return "";
  var formatter = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumSignificantDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(Math.floor(Number(num) * 100) / 100);
};
