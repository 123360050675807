import React, { useEffect, useState, useRef } from 'react';

import { observer } from 'mobx-react';

import api from '../../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

import './confirmation-style.css';

const Confirmation = observer((props) => {

    const [message, setMessage] = useState('Confirmando e-mail...');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current === true) {
            confirmarEmail();
            firstRender.current = false;
        }
    });

    function confirmarEmail() {
        setLoading(true);
        const token = props.match.params.token;

        api.patch(`/register/confirm/${token}`)
            .then(
                response => {
                    if (response.status === 200) {
                        setMessage('Seu email foi confirmado! Aguarde, você será direcionado para página de login!');
                        setTimeout(() => history.push('/cliente/login'), 4000)
                    } else if (response.status === 400) {
                        setMessage(response.data.error);
                    } else {
                        setMessage('Houve um erro na hora da confirmação do e-mail.');
                    }
                }
            )
            .catch((error) => {
                setMessage(error.response.data.error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div>
            <center>
                <div className="container-confirmation">
                    {loading ? <CircularProgress /> : null}
                    <h4>
                        {message}
                    </h4>
                </div>
            </center>
        </div>
    );
});

export default Confirmation;