import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

export default function ConfirmEmail(){

    const history = useHistory();

    useEffect(()=>{
        setTimeout( ()=> history.push('/cliente/login') ,10000)
    });

    return (
        <div>
            <center>
                <div className="confirm_email">
                    <h3>
                        Você precisa confirmar o seu email para ter acesso! Vá ate sua caixa de email e clique no link enviado!
                    </h3>
                </div>
            </center>
        </div>
    );
}