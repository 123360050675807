import React, { useState, useEffect, useContext } from "react";

import Menu from "../menu";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import Countdown, { zeroPad } from "react-countdown-now";
import { observer } from "mobx-react";

import "./styles.css";
import { StoreContext } from "../../store";
import Pagamento from "../pagamento";
import api from "../../../services/api";
import { currencyFormat } from '../../../utils/currency-formatter';

const Carrinho = observer((props) => {

  const [order, setOrder] = useState([]);
  const [qntdTotal, setQntdTotal] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [validade, setValidade] = useState(null);
  const storeContext = useContext(StoreContext);
  let cont = 0;
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [address, setAddress] = useState({});

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("order"))) {
      setOrder(JSON.parse(localStorage.getItem("order")));
    }
    setQntdTotal(localStorage.getItem("qntdTotal") ?? 0);
    setValorTotal(localStorage.getItem("valorTotal") ?? 0);
    setValidade(Date.parse(localStorage.getItem("validity")) ?? null);
    api
      .get(`authenticate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAddress(response.data.billingAddress || {});
      })
      .catch((err) => {
        console.log(err);
      });
    const idFieldSessioId = 'tanamao_session_id';
    const script = document.createElement("script");
    const clientId = process.env.REACT_APP_CLEARSALE_APP;
    script.innerHTML = `try{(function (a, b, c, d, e, f, g) {a['CsdpObject'] = e; a[e] = a[e] || function () {(a[e].q = a[e].q || []).push(arguments)}, a[e].l = 1 * new Date(); f = b.createElement(c),g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)})(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');csdp('app', '${clientId}');csdp('outputsessionid', '${idFieldSessioId}')['${idFieldSessioId}'];}catch(err){}`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      document.getElementById(idFieldSessioId).value = '';
    };
  }, [token]);

  function removeTicket(id) {
    const newOrder = [];
    let newValorTotal = 0;
    let n = 0;
    for (let i = 0; i < order.length; i++) {
      if (parseInt(order[i].id) !== parseInt(id)) {
        newOrder[n++] = order[i];
        newValorTotal += parseFloat(order[i].price + order[i].online_tax);
      }
    }

    localStorage.setItem("qntdTotal", qntdTotal - 1);
    localStorage.setItem("valorTotal", newValorTotal);
    localStorage.setItem("order", JSON.stringify(newOrder));

    storeContext.atualizaCarrinho(qntdTotal - 1);

    setQntdTotal(qntdTotal - 1);
    setValorTotal(newValorTotal);
    setOrder(newOrder);
    if (
      localStorage.getItem("qntdTotal") === "0" ||
      localStorage.getItem("qntdTotal") === null
    ) {
      localStorage.removeItem("order");
      localStorage.removeItem("qntdTotal");
      localStorage.removeItem("valorTotal");
      localStorage.removeItem("validity");
      setValidade(null);
      localStorage.removeItem("eventId");
      storeContext.setEventId(null);
    }
  }

  const Completionist = () => {
    localStorage.removeItem("order");
    localStorage.removeItem("qntdTotal");
    localStorage.removeItem("valorTotal");
    localStorage.removeItem("validity");
    localStorage.removeItem("eventId");
    setOrder(null);
    setQntdTotal(0);
    setValorTotal(0);
    setValidade(null);
    storeContext.atualizaCarrinho(0);
    storeContext.setEventId(null);
    return <span>You are good to go!</span>;
  };

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      handleClose();
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
        </span>
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="area_events_admin">
      <div className="content_client_geral grey_accent">
        <Menu />
        <div className="content_client">
          <Pagamento
            eventId={localStorage.getItem('eventId')}
            order={order}
            valorTotal={valorTotal}
            open={open}
            onClose={handleClose}
            onComplete={Completionist}
          />
          <h1>
            Carrinho
            {validade ? (
              <span>
                {" expira em "}
                <span style={{ color: "orange" }}>
                  <Countdown date={validade + 600000} renderer={renderer} />
                </span>
              </span>
            ) : (
              ""
            )}
          </h1>
          {localStorage.getItem("order") ? (
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                padding: '0 2em',
             }}>
              <div >
                {order.map((or) => {
                  cont++;
                  return (
                    <div
                      key={or.id}
                      className={cont % 2 === 0 ? "par cart" : "impar cart" }
                      style={{ margin: '1em 0', marginRight: '0.5em' }}
                    >
                      <div className="itens_cart" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                          <span className="event_name">{or.evento.name}</span>
                          <br />
                          <span className="event_date grey_accent2">
                            {format(
                              parseISO(or.evento.start_at),
                              "' Dia' dd 'de' MMMM', às ' HH:mm 'hrs' ",
                              { locale: pt }
                            )}
                          </span>
                          <br />
                          <span className="event_type orange_accent">
                            {or.ticketType.name + " - "}
                            {or.ticketType.type + " - "}
                          </span>

                          <span className="event_price">
                            { currencyFormat(or.price) + ' + ' + currencyFormat(or.online_tax) + ' de taxa = ' + currencyFormat(or.price + or.online_tax) } 
                          </span>
                        </div>

                        <div >
                          <button
                            className="btn_trash"
                            onClick={() => {
                              removeTicket(or.id);
                            }}
                          >
                            <FaTrashAlt />
                          </button>
                        </div>
                      </div>
                      <hr  style={{ borderTop: '1px dashed #e7e7e7' }}/>
                    </div>
                  );
                })}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ 
                  backgroundColor: '#e7e7e7',
                  alignContent: 'flex-end',
                  width: '100%',
                  justifyContent: 'flex-end'
                 }}>
                  <span style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        margin: '20px 0',
                        marginRight: '0.35em'
                  }} >Quantidade total de ingressos</span>
                 </div>
                <p
                  style={{
                    color: "#f8962e",
                    fontSize: 23,
                    letterSpacing: -2,
                    fontWeight: 600,
                  }}
                >
                  {qntdTotal}
                </p>
                <div style={{ 
                  backgroundColor: '#e7e7e7',
                  alignContent: 'flex-end',
                  width: '100%',
                  justifyContent: 'flex-end'
                 }}>
                  <span style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        margin: '20px 0',
                        marginRight: '0.35em'
                  }} >Valor total a pagar</span>
                 </div>
                <p
                  style={{
                    color: "#457f00",
                    fontSize: 30,
                    letterSpacing: -2,
                    fontWeight: 600,
                  }}
                >
                  {currencyFormat(valorTotal)}
                </p>
                <p>
                  <Link to={`/evento/${localStorage.getItem('eventId')}`}>
                    Clique aqui para adicionar mais ingressos
                  </Link>
                  <br />
                  <br />
                  ou
                  <br />
                  <br />
                  <button
                    className="btn_finalizarcompra"
                    onClick={handleClickOpen}
                    disabled={(!address.zip_code) ? 'disabled' : ''}
                  >
                    Finalizar compra
                  </button>
                  <br />
                  <br />
                  <br />
                  {!address.zip_code ? (
                    <span style={{ color: "red" }}>
                      Para finalizar a compra, <Link style={{ color: "red", textDecoration: 'underline' }} to='/cliente/endereco-cobranca?pagamentoEmAndamento=true'>
                      preenche os dados de endereço de
                      cobrança.
                      </Link>
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
          ) : (
            <div style={{ marginLeft: 50, color: "grey", fontSize: 22 }}>
              <span>Seu carrinho está vazio</span>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Carrinho;
